import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import MainMenu, {DrawerWidth} from "../../fragments/mainmenu/MainMenu";
import {
    Backdrop,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import ClientService from "./ClientService";
import RegisteringClient from "./RegisteringClient";

const drawerWidth = DrawerWidth;

export default function RegisteringClientPage() {
    const [loadingWheelVisible, setLoadingWheelVisible] = React.useState(false);
    const [registeringClient, setRegisteringClients] = React.useState<RegisteringClient[]>([]);

    const getRegisteringClients = () => {
        setLoadingWheelVisible(true);
        ClientService.getRegisteringList()
            .then((registeringClients) => {
                setLoadingWheelVisible(false);
                setRegisteringClients(registeringClients);
            });
    }

    React.useEffect(() => {
        getRegisteringClients();
    }, []);

    return (
        <Box sx={{display: 'flex'}}>
            <MainMenu/>
            <Box
                component="main"
                sx={{backgroundColor: '#f7f7f7', flexGrow: 1, p: 3, width: {sm: `calc(100% - ${drawerWidth}px)`}}}
            >
                <Toolbar/>

                {loadingWheelVisible ?
                    (
                        <Backdrop sx={{color: '#fff'}}
                                  open={loadingWheelVisible}>
                            <CircularProgress color="inherit"/>
                        </Backdrop>
                    )
                    : ''
                }
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Email</TableCell>
                                <TableCell>Telefon</TableCell>
                                <TableCell>Cod</TableCell>
                                <TableCell>Nume</TableCell>
                                <TableCell>Prenume</TableCell>
                                <TableCell>Pas</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {registeringClient.map((client) => (
                                <TableRow
                                    key={client.sessionId}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell>{client.email}</TableCell>
                                    <TableCell>{client.mobilePhone}</TableCell>
                                    <TableCell>{client.verificationCode}</TableCell>
                                    <TableCell>{client.firstName}</TableCell>
                                    <TableCell>{client.lastName}</TableCell>
                                    <TableCell>{client.step}</TableCell>
                                </TableRow>
                            ))}

                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

        </Box>
    );
}