export class ReportFilters {
    // trainingClassId:  string | null;
    // locationId:  string | null;
    // staffMemberId: string | null;
    periodBegin: Date | null;
    periodEnd: Date | null;
    // weekdays: string[];
    // beginHours: string[];

    constructor(
        periodBegin: Date | null,
        periodEnd: Date | null,
        // trainingClassId: string | null,
        // locationId: string | null,
        // staffMemberId: string | null,
        // weekdays: string[],
        // beginHours: string[]
    ) {
        this.periodBegin = periodBegin;
        this.periodEnd = periodEnd;
        // this.trainingClassId = trainingClassId;
        // this.locationId = locationId;
        // this.staffMemberId = staffMemberId;
        // this.weekdays = weekdays;
        // this.beginHours = beginHours;
    }

    toUrlSearchParams(): URLSearchParams {
        const params = new URLSearchParams();
        params.append("periodBegin", this.periodBegin ? this.periodBegin.toISOString() : '');
        params.append("periodEnd", this.periodEnd ? this.periodEnd.toISOString() : '');
        // params.append("trainingClassIds", this.trainingClassId ? this.trainingClassId : '');
        // params.append("locationIds", this.locationId ? this.locationId : '');
        // params.append("staffMemberIds", this.staffMemberId ? this.staffMemberId.toString() : '');
        // params.append("weekdays", this.weekdays ? this.weekdays.toString() : '');
        // params.append("beginHours", this.beginHours ? this.beginHours.toString() : '');
        return params;
    }
}
