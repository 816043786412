import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import MainMenu, {DrawerWidth} from "../../fragments/mainmenu/MainMenu";
import {
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Drawer,
    FormControl,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import CalendarService from "./CalendarService";
import {useSnackbar, VariantType} from "notistack";
import {useParams} from "react-router-dom";
import CalendarEvent from "./CalendarEvent";
import Location from "../location/Location";
import {dateToDateTimeStr} from "../../util/DateUtil";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import BookingCreateForm from "./BookingCreateForm";
import {bookingStatusMap} from "./BookingStatus";
import TrainingClass from "../trainingclass/TrainingClass";
import TrainingClassService from "../trainingclass/TrainingClassService";
import LocationService from "../location/LocationService";
import ClientSelector from "../../fragments/ClientSelector";
import ClientListItem from "../client/ClientListItem";
import ClientService, {ClientFilters} from "../client/ClientService";

const drawerWidth = DrawerWidth;

export default function BookingsPage() {
    const {enqueueSnackbar} = useSnackbar();
    const {eventId} = useParams();

    const [loadingWheelVisible, setLoadingWheelVisible] = React.useState(false);
    const [event, setEvent] = React.useState<CalendarEvent | null>(null);
    const [trainingClass, setTrainingClass] = React.useState<TrainingClass | null>(null);
    const [location, setLocation] = React.useState<Location | null>(null);
    const [clients, setClients] = React.useState<Map<number, ClientListItem>>(new Map());

    const [addClientId, setAddClientId] = React.useState('');
    const [addDrawerShown, setAddDrawerShown] = React.useState(false);

    const [deleteBookingId, setDeleteBookingId] = React.useState<number>(0);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

    const [addDialogOpen, setAddDialogOpen] = React.useState(false);

    const getEvent = () => {
        setLoadingWheelVisible(true);
        CalendarService.getEvent(Number.parseInt(typeof eventId === "string" ? eventId : "0"))
            .then((event) => {
                setLoadingWheelVisible(false);
                setEvent(event);
                getLocation();
                getTrainingClass();
                getEventClients(event);
            });
    }

    const getEventClients = (event: CalendarEvent) => {
        const clientIds = event.bookings.map(value => value.clientId);
        ClientService.getByIdIn(clientIds)
            .then((response) => {
                const clientMap: Map<number, ClientListItem> = new Map();
                response.map(client => clientMap.set(client.id, client));
                setClients(clientMap);
                setEvent(event);
            });
    }
    const getTrainingClass = () => {
        setLoadingWheelVisible(true);
        TrainingClassService.getList()
            .then((list) => {
                setLoadingWheelVisible(false);
                list.forEach(value => {
                    if (value.id == event?.trainingClassId) {
                        setTrainingClass(value)
                    }
                });
            });
    }

    const getLocation = () => {
        setLoadingWheelVisible(true);
        LocationService.getList()
            .then((list) => {
                setLoadingWheelVisible(false);
                list.forEach(value => {
                    if (value.id == event?.locationId) {
                        setLocation(value)
                    }
                });
            });
    }

    React.useEffect(() => {
        getEvent();
    }, []);

    function createBooking(form: BookingCreateForm) {
        CalendarService.createBooking(form)
            .then((response) => {
                if (200 === response.status) {
                    setAddDrawerShown(false);
                    setAddClientId('');
                    setLoadingWheelVisible(false);

                    const variant: VariantType = 'success';
                    enqueueSnackbar('Succes', {variant});
                    getEvent();
                } else {
                    response.json().then(json => {
                        const variant: VariantType = 'error';
                        enqueueSnackbar(json.message, {variant});
                    });
                }
            }).finally(() => {
            setLoadingWheelVisible(false);
        });
    }

    const onAddDialogClose = () => {
        setAddDialogOpen(false);
    }

    const onAddDialogNoClick = () => {
        setAddDialogOpen(false);
        setAddDrawerShown(false);
    }

    const onAddDialogYesClick = () => {
        setAddDialogOpen(false);
        const form = new BookingCreateForm(addClientId, eventId ? eventId : '');
        createBooking(form);
    }

    const onDeleteDialogClose = () => {
        setDeleteDialogOpen(false);
    }

    const onDeleteDialogNoClick = () => {
        setDeleteDialogOpen(false);
    }

    const onDeleteDialogYesClick = () => {
        setDeleteDialogOpen(false);
        CalendarService.deleteBooking(deleteBookingId)
            .then(() => {
                getEvent();
            });
    }

    const onAddDrawerClose = (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setAddDrawerShown(false);
    };

    const onAddButtonClick = (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setAddDrawerShown(true);
    };

    const onDeleteClick = (id: string) => {
        setDeleteBookingId(Number.parseInt(id));
        setDeleteDialogOpen(true);
    }

    const onAddClick = () => {
        const form = new BookingCreateForm(addClientId, eventId ? eventId : '');
        setLoadingWheelVisible(true);
        CalendarService.hasValidSubscriptionForEvent(form).then(
            response => {
                response.json().then(hasSubscription => {
                    if (hasSubscription) {
                        createBooking(form);
                    } else {
                        setAddDialogOpen(true);
                    }
                })
            }
        ).finally(() => {
            setLoadingWheelVisible(false);
        });
    }

    return (
        <Box sx={{display: 'flex'}}>
            <MainMenu/>
            <Box
                component="main"
                sx={{backgroundColor: '#f7f7f7', flexGrow: 1, p: 3, width: {sm: `calc(100% - ${drawerWidth}px)`}}}
            >
                <Toolbar/>

                {loadingWheelVisible ?
                    (
                        <Backdrop sx={{color: '#fff'}}
                                  open={loadingWheelVisible}>
                            <CircularProgress color="inherit"/>
                        </Backdrop>
                    )
                    : ''
                }
                {event ? (
                    <TableContainer component={Paper}>
                        <Typography component="h2" variant="h6" color="primary" px={2} py={0}>
                            {trainingClass?.name}
                            :: {dateToDateTimeStr(event.beginsAt)}
                            :: {location?.name}
                            :: {event.activeBookings().length} din {event.maxCapacity} locuri ocupate
                        </Typography>
                        <Button onClick={onAddButtonClick} variant="outlined" color="success" sx={{ml: 2, mt: 2}}>
                            <AddCircleIcon fontSize="small"/>
                        </Button>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Nume</TableCell>
                                    <TableCell>Abonament</TableCell>
                                    <TableCell>Creat la data</TableCell>
                                    <TableCell/>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {event.bookings.map((booking) => (
                                    <TableRow
                                        key={booking.id}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell>{bookingStatusMap.get(booking.status)}</TableCell>
                                        <TableCell>{clients.get(booking.clientId)?.firstName + " " + clients.get(booking.clientId)?.lastName}</TableCell>
                                        <TableCell>{booking.clientSubscription?.name}</TableCell>
                                        <TableCell>{dateToDateTimeStr(booking.createdAt)}</TableCell>
                                        <TableCell align="right">
                                            <IconButton aria-label="delete" size="small" color="error"
                                                        onClick={() => onDeleteClick(booking.id.toString())}>
                                                <DeleteIcon fontSize="small"/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}

                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : ''}
            </Box>

            <Drawer
                anchor='right'
                open={addDrawerShown}
                onClose={onAddDrawerClose}
            >
                <Typography component="h2" variant="h6" color="primary" px={2} py={1}>
                    Programeaza client
                </Typography>
                <Box sx={{width: 420, px: 2}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl sx={{width: '100%'}}>
                                <ClientSelector value={"2"} onChange={(newValue: string | null) => setAddClientId(newValue ? newValue : '')} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl sx={{width: '100%'}}>
                                <Button variant="contained" onClick={onAddClick}>Adauga programare</Button>
                            </FormControl>
                        </Grid>
                    </Grid>

                </Box>
            </Drawer>

            <Dialog
                open={deleteDialogOpen}
                onClose={onDeleteDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirmare pentru stergere"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Sigur vrei sa stergi aceasta rezervare?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onDeleteDialogNoClick} autoFocus>Nu</Button>
                    <Button onClick={onDeleteDialogYesClick}>Da</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={addDialogOpen}
                onClose={onAddDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirmare pentru adaugare"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Clientul nu are abonament activ la data respectiva. Doresti sa creezi programarea?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onAddDialogNoClick} autoFocus>Nu</Button>
                    <Button onClick={onAddDialogYesClick}>Da</Button>
                </DialogActions>
            </Dialog>

        </Box>
    );
}