import {Autocomplete} from "@mui/material";
import ClientListItem from "../routes/client/ClientListItem";
import TextField from "@mui/material/TextField";
import * as React from "react";
import ClientService, {ClientFilters} from "../routes/client/ClientService";
import {useState} from "react";

export default function ClientSelector(params: any) {
    let searchTimeout: NodeJS.Timeout | null = null;
    const searchTimeoutMs: number = 1000;
    const searchMinLength: number = 3;
    const searchMaxResults: number = 30;

    const [clients, setClients] = React.useState<Map<number, ClientListItem>>(new Map());

    const searchForClientsLike = (searchText: string) => {
        if (null != searchTimeout) {
            clearTimeout(searchTimeout);
        }
        if (searchText.length >= searchMinLength) {
            searchTimeout = setTimeout(() => {
                const clientFilters = new ClientFilters(null, searchText, 0, searchMaxResults);
                setClients(new Map);
                ClientService.getFilteredList(clientFilters)
                    .then((response) => {
                        const clientMap: Map<number, ClientListItem> = new Map();
                        response.items.map(client => clientMap.set(client.id, client));
                        setClients(clientMap);
                    });
                searchTimeout = null;
            }, searchTimeoutMs);
        }
    };

    return (
        <Autocomplete
            disablePortal
            options={Array.from(clients.values())}
            onChange={(event, newValue) => {
                if (typeof params.onChange == 'function') {
                    params.onChange(newValue ? newValue.id.toString() : '');
                }
            }}
            onInputChange={(event, newValue) => {
                //@ts-ignore
                searchForClientsLike(event.target.value);
            }}
            getOptionLabel={(client: ClientListItem) => client.firstName + " " + client.lastName + " ( " + client.email + " )"}
            renderInput={(params) =>
                <TextField {...params} label="Cauta client"/>
            }
        />

    );
}
