import {dateToTimeStr} from "../../util/DateUtil";

export default class CalendarEventCloneWeekForm {
    sourceDayFromWeek: Date | null;
    sourceLocationId: number;
    sourceTrainingClassId?: number;
    sourceWeekdays: string[] = [];
    destinationIntervalBegin: Date | null;
    destinationIntervalEnd: Date | null;
    destinationStaffMemberId?: number;
    destinationLocationId?: number;
    overwrite: boolean;

    constructor(sourceDayFromWeek: Date | null,
                sourceLocationId: string,
                sourceTrainingClassId: string | null,
                sourceWeekdays: string[],
                destinationIntervalBegin: Date | null,
                destinationIntervalEnd: Date | null,
                destinationStaffMemberId: string,
                destinationLocationId: string,
                overwrite: boolean) {
        this.sourceDayFromWeek = sourceDayFromWeek;
        this.sourceLocationId = Number.parseInt(sourceLocationId);
        this.sourceTrainingClassId = sourceTrainingClassId ? Number.parseInt(sourceTrainingClassId) : undefined;
        this.sourceWeekdays = sourceWeekdays;
        this.destinationIntervalBegin = destinationIntervalBegin;
        this.destinationIntervalEnd = destinationIntervalEnd;
        this.destinationStaffMemberId = destinationStaffMemberId ? Number.parseInt(destinationStaffMemberId) : undefined;
        this.destinationLocationId = destinationLocationId ? Number.parseInt(destinationLocationId) : undefined;
        this.overwrite = overwrite;
    }
}
