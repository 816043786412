import {convertToDate, dateToDateStr, dateToTimeStr} from "../../util/DateUtil";

export default class BookingReportItem {
    beginsAtDate: string;
    beginsAtTime: string;
    trainingClassName: string;
    locationName: string;
    instructorName: string;
    clientName: string;
    clientCountry: string;
    clientRegion: string;
    clientCity: string;
    clientAddress: string;
    clientPostalCode: string;
    clientId: number;
    status: string;
    id: number;

    constructor(item: any) {
        const beginsAt = convertToDate(item.beginsAt);
        this.beginsAtDate = null == beginsAt ? '' : dateToDateStr(beginsAt);
        this.beginsAtTime = null == beginsAt ? '' : dateToTimeStr(beginsAt);
        this.trainingClassName = item.trainingClassName;
        this.locationName = item.locationName;
        this.instructorName = item.instructorName;
        this.clientName = item.clientName;
        this.clientCountry = item.clientCountry;
        this.clientRegion = item.clientRegion;
        this.clientCity = item.clientCity;
        this.clientAddress = item.clientAddress;
        this.clientPostalCode = item.clientPostalCode;
        this.clientId = item.clientId;
        this.status = item.status;
        this.id = item.id;
    }

}