import BackendService from "../../config/BackendService";
import Invoice from "../invoice/Invoice";

export class InvoiceFilters {
    id: string | null;
    paymentId: string | null;
    number: string | null;
    series: string | null;
    generated: string | null;
    downloaded: string | null;
    emailSent: string | null;
    page: number;
    perPage: number;

    constructor(id: string | null,
                paymentId: string | null,
                number: string | null,
                series: string | null,
                generated: string | null,
                downloaded: string | null,
                emailSent: string | null,
                page: number,
                perPage: number) {
        this.id = id;
        this.paymentId = paymentId;
        this.number = number;
        this.series = series;
        this.generated = generated;
        this.downloaded = downloaded;
        this.emailSent = emailSent;
        this.page = page;
        this.perPage = perPage;
    }

    toUrlSearchParams(): URLSearchParams {
        const params = new URLSearchParams();
        params.append("id", this.id ? this.id : '');
        params.append("paymentId", this.paymentId ? this.paymentId : '');
        params.append("number", this.number ? this.number : '');
        params.append("series", this.series ? this.series : '');
        params.append("generated", this.generated ? this.generated : '');
        params.append("downloaded", this.downloaded ? this.downloaded : '');
        params.append("emailSent", this.emailSent ? this.emailSent : '');
        params.append("page", this.page.toString());
        params.append("perPage", this.perPage.toString());
        return params;
    }
}

export class InvoiceListResponse {
    items: Invoice[];
    totalItems: number;

    constructor(items: Invoice[], totalItems: number) {
        this.items = items;
        this.totalItems = totalItems;
    }
}

export default class InvoiceService {
    static getList(filters: InvoiceFilters): Promise<InvoiceListResponse> {
        return BackendService.getJson('/api/v1/invoice', filters.toUrlSearchParams())
            .then(response => {
                const items = response.content.map((item: any) => new Invoice(item));
                return new InvoiceListResponse(items, response.totalElements);
            });
    }

    static approve(id: number): Promise<Response> {
        return BackendService.get('/api/v1/invoice/approve/' + id);
    }
}
