export default class ClientPhoto {
    clientId: number;
    contentData: string;
    contentType: string;
    contentSize: number;

    constructor(
        clientId: number,
        contentData: string,
        contentType: string,
        contentSize: number,
    ) {
        this.clientId = clientId;
        this.contentData = contentData;
        this.contentType = contentType;
        this.contentSize = contentSize;
    }
}