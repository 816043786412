import {convertToDate} from "../../util/DateUtil";
import {BookingStatus} from "./BookingStatus";
import User from "../account/User";
import ClientSubscription from "../subscription/ClientSubscription";

export default class Booking {
    id: number;
    clientId: number;
    calendarEventId: number;
    createdAt: Date;
    status: BookingStatus;
    clientSubscriptionId: number | null;
    clientSubscription: ClientSubscription | null;
    statusModifiedAt: Date | null;
    statusModifiedBy: User | null;

    constructor(booking: any) {
        this.id = booking.id;
        this.clientId = booking.clientId;
        this.calendarEventId = booking.calendarEventId;
        // @ts-ignore
        this.createdAt = convertToDate(booking.createdAt);
        this.status = booking.status;
        this.clientSubscriptionId = booking.clientSubscriptionId;
        this.clientSubscription = booking.clientSubscription ? new ClientSubscription(booking.clientSubscription) : null;
        this.statusModifiedAt = convertToDate(booking.statusModifiedAt);
        this.statusModifiedBy = booking.statusModifiedBy;
    }

    public isActive(): boolean {
        return this.status != BookingStatus.EARLY_CANCEL && this.status != BookingStatus.LATE_CANCEL && this.status != BookingStatus.DELETED;
    }

    public isCheckinVisible(): boolean {
        return this.status != BookingStatus.EARLY_CANCEL && this.status != BookingStatus.DELETED;
    }
}