export enum PromotionType {
    NEW_ONLY = "NEW_ONLY",
    EXISTING_ONLY = "EXISTING_ONLY",
    NEW_AND_EXISTING = "NEW_AND_EXISTING",
    ZERO_SALES = "ZERO_SALES",
    PLUS_ONE_NEW_ONLY = "PLUS_ONE_NEW_ONLY"
}

export const promotionTypes: Map<PromotionType, string> = new Map([
    [PromotionType.NEW_ONLY, "Pentru utilizatori noi"],
    [PromotionType.EXISTING_ONLY, "Pentru utilizatori existenti"],
    [PromotionType.NEW_AND_EXISTING, "Pentru oricine"],
    [PromotionType.ZERO_SALES, "Pentru utilizatori cu zero vanzari"],
    [PromotionType.PLUS_ONE_NEW_ONLY, "Macar un utilizator nou (plus unu)"]
]);
