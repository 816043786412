import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import MainMenu, {DrawerWidth} from "../../fragments/mainmenu/MainMenu";
import {
    Backdrop,
    CircularProgress, FormControl,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from "@mui/material";
import InboxIcon from '@mui/icons-material/Inbox';
import ClientReportItem from '../client/ClientReportItem';
import SalesReportItem from '../payment/SalesReportItem';
import ClientService from "../client/ClientService";
import PaymentService from "../payment/PaymentService";
import DemoSubscriptionReportItem from "../subscription/DemoSubscriptionReportItem";
import ClientSubscriptionService from "../subscription/ClientSubscriptionService";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import roLocale from "date-fns/locale/ro";
import DateRangePicker, {DateRange} from "@mui/lab/DateRangePicker";
import TextField from "@mui/material/TextField";
import {LocalizationProvider} from "@mui/lab";
import CalendarService, {EventFilters} from "../calendar/CalendarService";
import {ReportFilters} from "./ReportFilters";
import CalendarEvent from "../calendar/CalendarEvent";
import {dateToDay} from "../../util/DateUtil";
import BookingReportItem from "../calendar/BookingReportItem";
import Client from "../client/Client";


const drawerWidth = DrawerWidth;
const today = new Date();
today.setHours(23);
today.setMinutes(59);
today.setSeconds(59);
const oneMonthAgo = new Date();
oneMonthAgo.setHours(0);
oneMonthAgo.setMinutes(0);
oneMonthAgo.setSeconds(0);
oneMonthAgo.setMilliseconds(0);
oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

function csvEscapeCell(text: any) {
    if (null == text) {
        text = '';
    }
    text = text.toString();
    return '"' + text.replaceAll('"', '""') + '"';
}

function generateCsv(headerObject: any, allRows: any, fileName: string) {
    let csvData = allRows.map((item: any) => {
        let row: any[] = [];
        for (let key in item) {
            // @ts-ignore
            row.push(csvEscapeCell(item[key]));
        }
        return row.join(',');
    }).join("\n");
    let row: any[] = [];
    for (let key in headerObject) {
        // @ts-ignore
        row.push(csvEscapeCell(key));
    }
    csvData = row.join(',') + "\n" + csvData;

    let hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvData);

    //provide the name for the CSV file to be downloaded
    hiddenElement.download = fileName;
    hiddenElement.click();
}

export default function ReportPage() {

    const [loadingWheelVisible, setLoadingWheelVisible] = React.useState(false);
    const [period, setPeriod] = React.useState<DateRange<Date>>([oneMonthAgo, today]);
    let filters = new ReportFilters(period[0], period[1]);

    const onReportActiveClientsTotalClick = () => {
        setLoadingWheelVisible(true);
        ClientService.getReportActiveClientsTotal(filters).then(allRows => {
            setLoadingWheelVisible(false);
            generateCsv(new ClientReportItem({}), allRows, 'report_active_clients_total.csv');
        });
    }

    const onReportPromotionalEmailClick = () => {
        setLoadingWheelVisible(true);
        ClientService.getReportPromotionalEmailList(filters).then(allRows => {
            setLoadingWheelVisible(false);
            generateCsv(new Client({}), allRows, 'report_promotional_email_list.csv');
        });
    }

    const onReportSalesClick = () => {
        PaymentService.getReportSales(filters).then(allRows => {
            setLoadingWheelVisible(false);
            generateCsv(new SalesReportItem({}), allRows, 'report_sales.csv')
        });
    }

    const onReportBookingsClick = () => {
        setLoadingWheelVisible(true);
        CalendarService.getBookingsReport(filters)
            .then((allRows) => {
                setLoadingWheelVisible(false);
                generateCsv(new BookingReportItem({}), allRows, 'report_bookings.csv')
            });
    }

    const onReportDemosClick = () => {
        ClientSubscriptionService.getReportDemoSubscriptions(filters).then(allRows => {
            setLoadingWheelVisible(false);
            generateCsv(new DemoSubscriptionReportItem({}), allRows, 'report_demo.csv')
        });
    }

    return (
        <Box sx={{display: 'flex'}}>
            <MainMenu/>
            <Box
                component="main"
                sx={{backgroundColor: '#f7f7f7', flexGrow: 1, p: 3, width: {sm: `calc(100% - ${drawerWidth}px)`}}}
            >
                <Toolbar/>

                <LocalizationProvider dateAdapter={AdapterDateFns} locale={roLocale}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                            <FormControl>
                                <DateRangePicker
                                    startText="De pe"
                                    endText="Pana pe"
                                    value={period}
                                    onChange={(newValue) => {
                                        setPeriod(newValue);
                                    }}
                                    renderInput={(startProps, endProps) => (
                                        <>
                                            <TextField {...startProps} sx={{mr: 2}}/>
                                            <TextField {...endProps}/>
                                        </>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </LocalizationProvider>

                {loadingWheelVisible ?
                    (
                        <Backdrop sx={{color: '#fff'}}
                                  open={loadingWheelVisible}>
                            <CircularProgress color="inherit"/>
                        </Backdrop>
                    )
                    : ''
                }
                <List>
                    <ListItem disablePadding>
                        <ListItemButton onClick={onReportActiveClientsTotalClick}>
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary="Raport clienti activi total" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={onReportPromotionalEmailClick}>
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary="Raport clienti cu emailuri promotionale" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={onReportSalesClick}>
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary="Raport Vanzari" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={onReportBookingsClick}>
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary="Raport programari" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={onReportDemosClick}>
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary="Raport demo" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary="Raport recurenta abonamente" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary="Total conturi create" />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>

        </Box>
    );
}