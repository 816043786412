import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import MainMenu, {DrawerWidth} from "../../fragments/mainmenu/MainMenu";
import TextField from '@mui/material/TextField';
import DateRangePicker, {DateRange} from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import roLocale from 'date-fns/locale/ro';
import {
    Backdrop,
    Button,
    ButtonGroup,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Drawer,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import CalendarService, {EventFilters} from "./CalendarService";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloneIcon from '@mui/icons-material/CopyAllOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import LocationService from "../location/LocationService";
import StaffMemberService from "../staffmember/StaffMemberService";
import Location from "../location/Location";
import StaffMember from "../staffmember/StaffMember";
import CalendarEvent from "./CalendarEvent";
import {dateToDateStr, dateToDay, dateToTimeStr} from "../../util/DateUtil";
import TrainingClassService from "../trainingclass/TrainingClassService";
import TrainingClass from "../trainingclass/TrainingClass";
import {DatePicker} from "@mui/lab";
import {useSnackbar, VariantType} from "notistack";
import CalendarEventEditForm from "./CalendarEventEditForm";
import {Link} from "react-router-dom";
import CalendarEventCloneWeekForm from "./CalendarEventCloneWeekForm";
import CalendarPageGenerateDrawer from "./CalendarPageGenerateDrawer";

const drawerWidth = DrawerWidth;

const today = new Date();
const oneWeekFromNow = new Date();
oneWeekFromNow.setDate(oneWeekFromNow.getDate() + 7);

const beginHoursOptions: string[] = [
    '7', '8', '9',
    '10', '11', '12',
    '13', '14', '15',
    '16', '17', '18',
    '19', '20'
];

export default function CalendarPage() {
    const {enqueueSnackbar} = useSnackbar();

    const [loadingWheelVisible, setLoadingWheelVisible] = React.useState(false);
    const [period, setPeriod] = React.useState<DateRange<Date>>([today, oneWeekFromNow]);
    const [trainingClassId, setTrainingClassId] = React.useState('');
    const [locationId, setLocationId] = React.useState('');
    const [staffMemberId, setStaffMemberId] = React.useState('');
    const [weekdays, setWeekdays] = React.useState<string[]>(() => []);
    const [beginHours, setBeginHours] = React.useState<string[]>(() => []);
    const [trainingClasses, setTrainingClasses] = React.useState<TrainingClass[]>([]);
    const [locations, setLocations] = React.useState<Location[]>([]);
    const [staffMembers, setStaffMembers] = React.useState<StaffMember[]>([]);
    const [eventsByDay, setEventsByDay] = React.useState<Map<string, CalendarEvent[]>>(new Map());

    const [genDrawerShown, setGenDrawerShown] = React.useState(false);

    const [cloneDrawerShown, setCloneDrawerShown] = React.useState(false);
    const [cloneFormSourceDayFromWeek, setCloneFormSourceDayFromWeek] = React.useState<Date | null>(new Date());
    const [cloneFormSourceLocationId, setCloneFormSourceLocationId] = React.useState('');
    const [cloneFormSourceTrainingClassId, setCloneFormSourceTrainingClassId] = React.useState('');
    const [cloneFormSourceWeekdays, setCloneFormSourceWeekdays] = React.useState<string[]>(() => []);
    const [cloneFormDestinationIntervalBegin, setCloneFormDestinationIntervalBegin] = React.useState<Date | null>(new Date());
    const [cloneFormDestinationIntervalEnd, setCloneFormDestinationIntervalEnd] = React.useState<Date | null>(new Date());
    const [cloneFormDestinationStaffMemberId, setCloneFormDestinationStaffMemberId] = React.useState('');
    const [cloneFormDestinationLocationId, setCloneFormDestinationLocationId] = React.useState('');
    const [cloneFormOverwrite, setCloneFormOverwrite] = React.useState(false);

    const [editDrawerShown, setEditDrawerShown] = React.useState(false);
    const [editFormTrainingClassId, setEditFormTrainingClassId] = React.useState('');
    const [editFormLocationId, setEditFormLocationId] = React.useState('');
    const [editFormStaffMemberId, setEditFormStaffMemberId] = React.useState('');
    const [editFormMaxCapacity, setEditFormMaxCapacity] = React.useState('');
    const [editFormWaitingListSize, setEditFormWaitingListSize] = React.useState('');

    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

    const [selectedItems, setSelectedItems] = React.useState<number[]>(() => []);

    const getEvents = () => {
        if (null == period[0] || null == period[1]) {
            return;
        }
        setLoadingWheelVisible(true);
        setSelectedItems([]);
        let eventFilters = new EventFilters(
            period[0], period[1],
            trainingClassId,
            locationId,
            staffMemberId,
            weekdays,
            beginHours,
            false);
        CalendarService.getEvents(eventFilters)
            .then((events) => {
                setLoadingWheelVisible(false);
                const eventsByDay = new Map<string, CalendarEvent[]>();
                events.forEach((event: CalendarEvent) => {
                    const day: string = dateToDay(event.beginsAt);
                    if (!eventsByDay.has(day)) {
                        eventsByDay.set(day, []);
                    }
                    const dailyEvents: CalendarEvent[] | undefined = eventsByDay.get(day);
                    // @ts-ignore
                    dailyEvents.push(event);
                });
                setEventsByDay(eventsByDay)
            });
    }

    React.useEffect(() => {
        TrainingClassService.getList()
            .then(trainingClasses => setTrainingClasses(trainingClasses));
        LocationService.getList()
            .then(locations => setLocations(locations));
        StaffMemberService.getList()
            .then(staffMembers => setStaffMembers(staffMembers));
    }, []);

    React.useEffect(() => {
        getEvents();
    }, [trainingClassId, locationId, staffMemberId, period, weekdays, beginHours]);

    const toggleEditDrawer = (shown: boolean) => {
        if (shown) {
            setEditFormTrainingClassId('');
            setEditFormLocationId('');
            setEditFormStaffMemberId('');
            setEditFormMaxCapacity('');
            setEditFormWaitingListSize('');
        }
        setEditDrawerShown(shown);
    };

    const onDeleteDialogClose = () => {
        setDeleteDialogOpen(false);
    }

    const onDeleteDialogNoClick = () => {
        setDeleteDialogOpen(false);
    }

    const onDeleteDialogYesClick = () => {
        setDeleteDialogOpen(false);
        CalendarService.delete(selectedItems)
            .then(() => {
            getEvents();
        })
    }

    const onCheckAllButtonClick = () => {
        const allIds: number[] = [];
        eventsByDay.forEach(dailyEvents => dailyEvents.forEach(event => {
            allIds.push(event.id);
        }));
        setSelectedItems(allIds);
    }

    const getTrainingClass = (id: number) => trainingClasses.filter(value => value.id == id)[0]
    const getStaffMember = (id: number) => staffMembers.filter(value => value.id == id)[0]
    const getLocation = (id: number) => locations.filter(value => value.id == id)[0]

    const onCloneDrawerClose = (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setCloneDrawerShown(false);
    };

    const onEditDrawerClose = (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        toggleEditDrawer(false);
    };

    const onGenerateButtonClick = (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setGenDrawerShown(true);
    };

    const onCloneButtonClick = (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setCloneDrawerShown(true);
    };

    const onMultipleEditButtonClick = (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        if (0 === selectedItems.length) {
            return;
        }
        toggleEditDrawer(true);
    };

    const onItemCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newSelectedItems: number[] = JSON.parse(JSON.stringify(selectedItems));
        const value = Number.parseInt(event.target.name);
        const checked = event.target.checked;
        if (checked) {
            newSelectedItems.push(value);
        } else {
            newSelectedItems.splice(newSelectedItems.indexOf(value), 1);
        }
        setSelectedItems(newSelectedItems);
    }

    const onDayCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newSelectedItems: number[] = JSON.parse(JSON.stringify(selectedItems));
        const dayLabel = event.target.name;
        const checked = event.target.checked;
        // @ts-ignore
        Array.from(eventsByDay.get(dayLabel).values()).map(item => {
            if (checked) {
                newSelectedItems.push(item.id);
            } else {
                newSelectedItems.splice(newSelectedItems.indexOf(item.id), 1);
            }
        });
        setSelectedItems(newSelectedItems);
    }

    const onSingleEditClick = (id: string) => {
        setSelectedItems([Number.parseInt(id)]);
        toggleEditDrawer(true);
    }

    const onSingleDeleteClick = (id: string) => {
        setSelectedItems([Number.parseInt(id)]);
        setDeleteDialogOpen(true);
    }

    const onMultipleDeleteButtonClick = (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        if (0 === selectedItems.length) {
            return;
        }
        setDeleteDialogOpen(true);
    };

    const onCloneClick = () => {
        if (cloneFormDestinationIntervalBegin && cloneFormDestinationIntervalEnd && cloneFormSourceLocationId) {
            setLoadingWheelVisible(true);
            const form = new CalendarEventCloneWeekForm(
                cloneFormSourceDayFromWeek,
                cloneFormSourceLocationId,
                cloneFormSourceTrainingClassId,
                cloneFormSourceWeekdays,
                cloneFormDestinationIntervalBegin,
                cloneFormDestinationIntervalEnd,
                cloneFormDestinationStaffMemberId,
                cloneFormDestinationLocationId,
                cloneFormOverwrite
            );
            CalendarService.cloneWeekEvents(form)
                .then((response) => {
                    setLoadingWheelVisible(false);
                    if (200 === response.status) {
                        setCloneDrawerShown(false);

                        setCloneFormSourceDayFromWeek(new Date());
                        setCloneFormSourceLocationId("");
                        setCloneFormSourceTrainingClassId('');
                        setCloneFormSourceWeekdays([]);
                        setCloneFormDestinationLocationId('');
                        setCloneFormOverwrite(false);

                        const variant: VariantType = 'success';
                        enqueueSnackbar('Succes', {variant});
                        getEvents();
                    } else {
                        response.json().then(json => {
                            const variant: VariantType = 'error';
                            enqueueSnackbar(json.message, {variant});
                        });
                    }
                });
        }
    }

    const onSaveClick = () => {
        const form = new CalendarEventEditForm(
            selectedItems,
            editFormTrainingClassId,
            editFormLocationId,
            editFormStaffMemberId,
            editFormMaxCapacity,
            editFormWaitingListSize
        );
        setLoadingWheelVisible(true);
        CalendarService.save(form)
            .then((response) => {
                if (200 === response.status) {
                    setEditDrawerShown(false);
                    setLoadingWheelVisible(false);

                    const variant: VariantType = 'success';
                    enqueueSnackbar('Succes', {variant});
                    getEvents();
                } else {
                    response.json().then(json => {
                        const variant: VariantType = 'error';
                        enqueueSnackbar(json.message, {variant});
                    });
                }
            });
    }

    const onGenDrawerSuccess = () => {
        setGenDrawerShown(false);
        const variant: VariantType = 'success';
        enqueueSnackbar('Succes', {variant});
        getEvents();
    };

    const onGenDrawerError = (errorMessage: string) => {
        const variant: VariantType = 'error';
        enqueueSnackbar(errorMessage, {variant});
    };

    const onGenDrawerClose = () => {
        setGenDrawerShown(false);
    };

    let tables: JSX.Element;
    if (loadingWheelVisible) {
        tables = <Backdrop sx={{color: '#fff'}} open={loadingWheelVisible}>
            <CircularProgress color="inherit"/>
        </Backdrop>;
    } else {
        tables =
            <>
                {Array.from(eventsByDay.values()).map((dailyEvents: CalendarEvent[]) => (
                    <Grid item xs={12} md={12} lg={12} pt={2} key={dailyEvents[0].beginsAt.getTime()}>
                        <TableContainer component={Paper}>
                            <Typography component="h2" variant="h6" color="primary" px={2} py={0}>
                                {dateToDateStr(dailyEvents[0].beginsAt)}
                            </Typography>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox size="small"
                                                      name={dateToDay(dailyEvents[0].beginsAt)}
                                                      onChange={onDayCheckboxChange}/>
                                        </TableCell>
                                        <TableCell>Interval</TableCell>
                                        <TableCell>Rezervari</TableCell>
                                        <TableCell>Clasa</TableCell>
                                        <TableCell>Instructor</TableCell>
                                        <TableCell>Locatie</TableCell>
                                        <TableCell/>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dailyEvents.map((event) => (
                                        <TableRow
                                            key={event.id}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell>
                                                <Checkbox size="small" name={event.id.toString()}
                                                          checked={selectedItems.indexOf(event.id) > -1}
                                                          onChange={onItemCheckboxChange}/>
                                            </TableCell>
                                            <TableCell>{dateToTimeStr(event.beginsAt)} - {dateToTimeStr(event.endsAt)}</TableCell>
                                            <TableCell><Link to={'/calendar/bookings/' + event.id}>{event.activeBookings().length} / {event.maxCapacity}</Link></TableCell>
                                            <TableCell>{getTrainingClass(event.trainingClassId).name}</TableCell>
                                            <TableCell>{getStaffMember(event.staffMemberId) ? getStaffMember(event.staffMemberId).name : '(n/a)'}</TableCell>
                                            <TableCell>{getLocation(event.locationId).name}</TableCell>
                                            <TableCell align="right">
                                                <IconButton aria-label="edit" size="small" color="success"
                                                            onClick={() => onSingleEditClick(event.id.toString())}>
                                                    <EditIcon fontSize="small"/>
                                                </IconButton>
                                                <IconButton aria-label="delete" size="small" color="error"
                                                            onClick={() => onSingleDeleteClick(event.id.toString())}>
                                                    <DeleteIcon fontSize="small"/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                ))}
            </>;
    }

    return (
        <Box sx={{display: 'flex'}}>
            <MainMenu/>
            <Box
                component="main"
                sx={{backgroundColor: '#f7f7f7', flexGrow: 1, p: 3, width: {sm: `calc(100% - ${drawerWidth}px)`}}}
            >
                <Toolbar/>

                <LocalizationProvider dateAdapter={AdapterDateFns} locale={roLocale}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                            <FormControl>
                                <DateRangePicker
                                    startText="De pe"
                                    endText="Pana pe"
                                    value={period}
                                    onChange={(newValue) => {
                                        setPeriod(newValue);
                                    }}
                                    renderInput={(startProps, endProps) => (
                                        <>
                                            <TextField {...startProps} sx={{mr: 2}}/>
                                            <TextField {...endProps}/>
                                        </>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                            <FormControl sx={{width: '100%'}}>
                                <InputLabel id="label1">Clasa</InputLabel>
                                <Select
                                    labelId="label1"
                                    id="select1"
                                    value={trainingClassId}
                                    label="Clasa"
                                    onChange={(event: SelectChangeEvent) => {
                                        setTrainingClassId(event.target.value as string);
                                    }}
                                >
                                    <MenuItem value=''>( toate )</MenuItem>
                                    {trainingClasses.map((trainingClass: TrainingClass) => (
                                        <MenuItem key={trainingClass.id}
                                                  value={trainingClass.id}>{trainingClass.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                            <FormControl sx={{width: '100%'}}>
                                <InputLabel id="locationIdLabel">Locatie</InputLabel>
                                <Select
                                    labelId="locationIdLabel"
                                    id="locationId"
                                    value={locationId}
                                    label="Locatie"
                                    onChange={(event: SelectChangeEvent) => {
                                        setLocationId(event.target.value as string);
                                    }}
                                >
                                    <MenuItem value=''>( toate )</MenuItem>
                                    {locations.map((location: Location) => (
                                        <MenuItem key={location.id} value={location.id}>{location.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                            <FormControl sx={{width: '100%'}}>
                                <InputLabel id="staffMemberIdLabel">Instructor</InputLabel>
                                <Select
                                    labelId="staffMemberIdLabel"
                                    id="staffMemberId"
                                    value={staffMemberId}
                                    label="Instructor"
                                    onChange={(event: SelectChangeEvent) => {
                                        setStaffMemberId(event.target.value as string);
                                    }}
                                >
                                    <MenuItem value=''>( toate )</MenuItem>
                                    {staffMembers.map((staffMember: StaffMember) => (
                                        <MenuItem key={staffMember.id}
                                                  value={staffMember.id}>{staffMember.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                            <FormControl sx={{width: '100%'}}>
                                <InputLabel id="beginHoursLabel">Ora inceput</InputLabel>
                                <Select
                                    labelId="beginHoursLabel"
                                    id="beginHours"
                                    multiple
                                    value={beginHours}
                                    label="Ora inceput"
                                    onChange={(event: SelectChangeEvent<string[]>) => {
                                        setBeginHours(event.target.value as string[]);
                                    }}
                                >
                                    <MenuItem value=''>( toate )</MenuItem>
                                    {beginHoursOptions.map((beginHour: string) => (
                                        <MenuItem key={beginHour}
                                                  value={beginHour}>{beginHour}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                            <FormControl sx={{width: '100%'}}>
                                <ToggleButtonGroup
                                    value={weekdays}
                                    onChange={(
                                        event: React.MouseEvent<HTMLElement>,
                                        newValues: string[],
                                    ) => {
                                        setWeekdays(newValues);
                                    }}
                                    aria-label="Zile din saptamana"
                                    color="primary"
                                >
                                    <ToggleButton value="0" aria-label="Luni">Lu</ToggleButton>
                                    <ToggleButton value="1" aria-label="Marti">Ma</ToggleButton>
                                    <ToggleButton value="2" aria-label="Miercuri">Mi</ToggleButton>
                                    <ToggleButton value="3" aria-label="Joi">Jo</ToggleButton>
                                    <ToggleButton value="4" aria-label="Vineri">Vi</ToggleButton>
                                    <ToggleButton value="5" aria-label="Sambata">Sa</ToggleButton>
                                    <ToggleButton value="6" aria-label="Duminica">Du</ToggleButton>
                                </ToggleButtonGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <ButtonGroup variant="outlined" aria-label="outlined button group">
                                <Button onClick={onGenerateButtonClick} color="success">
                                    <AddCircleIcon fontSize="small"/>
                                </Button>
                                <Button onClick={onCloneButtonClick} color="success">
                                    <CloneIcon fontSize="small"/>
                                </Button>
                                <Button color="warning" onClick={onCheckAllButtonClick}>
                                    <CheckIcon fontSize="small"/>
                                </Button>
                                {(selectedItems.length > 0) ? (
                                    <>
                                        <Button onClick={onMultipleEditButtonClick} color="success">
                                            <EditIcon fontSize="small"/>
                                        </Button>
                                        <Button color="error" onClick={onMultipleDeleteButtonClick}>
                                            <DeleteIcon fontSize="small"/>
                                        </Button>
                                    </>
                                ) : ''}
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </LocalizationProvider>

                {tables}

            </Box>

            {genDrawerShown ? <CalendarPageGenerateDrawer
                onSuccess={onGenDrawerSuccess}
                onError={onGenDrawerError}
                onClose={onGenDrawerClose}
            /> : ''}

            <Drawer
                anchor='right'
                open={cloneDrawerShown}
                onClose={onCloneDrawerClose}
            >
                <Typography component="h2" variant="h6" color="primary" px={2} py={1}>
                    Cloneaza program
                </Typography>
                <Box sx={{width: 420, px: 2}}>
                    <FormControl sx={{width: '100%', mb: 2}}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={roLocale}>
                            <DatePicker
                                label={"O zi din saptamana sursa"}
                                value={cloneFormSourceDayFromWeek}
                                onChange={(newValue) => {
                                    setCloneFormSourceDayFromWeek(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </FormControl>
                    <FormControl sx={{width: '100%', mb: 2}}>
                        <InputLabel id="cloneFormSourceLocationIdLabel">Locatie sursa</InputLabel>
                        <Select
                            labelId="cloneFormSourceLocationIdLabel"
                            id="cloneFormSourceLocationId"
                            value={cloneFormSourceLocationId}
                            label="Locatie sursa"
                            onChange={(event: SelectChangeEvent) => {
                                setCloneFormSourceLocationId(event.target.value as string);
                            }}
                        >
                            <MenuItem value=''>( alege )</MenuItem>
                            {locations.map((location: Location) => (
                                <MenuItem key={location.id} value={location.id}>{location.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{width: '100%', mb: 2}}>
                        <InputLabel id="cloneFormSourceTrainingClassIdLabel">Clasa sursa</InputLabel>
                        <Select
                            labelId="cloneFormSourceTrainingClassIdLabel"
                            id="cloneFormSourceTrainingClassId"
                            value={cloneFormSourceTrainingClassId}
                            label="Clasa"
                            onChange={(event: SelectChangeEvent) => {
                                setCloneFormSourceTrainingClassId(event.target.value as string);
                            }}
                        >
                            <MenuItem value=''>( alege )</MenuItem>
                            {trainingClasses.map((trainingClass: TrainingClass) => (
                                <MenuItem key={trainingClass.id}
                                          value={trainingClass.id}>{trainingClass.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{width: '100%', mb: 2}}>
                        <ToggleButtonGroup
                            value={cloneFormSourceWeekdays}
                            onChange={(
                                event: React.MouseEvent<HTMLElement>,
                                newValues: string[],
                            ) => {
                                setCloneFormSourceWeekdays(newValues);
                            }}
                            aria-label="Zile din saptamana"
                            color="primary"
                        >
                            <ToggleButton value="0" aria-label="Luni">Lu</ToggleButton>
                            <ToggleButton value="1" aria-label="Marti">Ma</ToggleButton>
                            <ToggleButton value="2" aria-label="Miercuri">Mi</ToggleButton>
                            <ToggleButton value="3" aria-label="Joi">Jo</ToggleButton>
                            <ToggleButton value="4" aria-label="Vineri">Vi</ToggleButton>
                            <ToggleButton value="5" aria-label="Sambata">Sa</ToggleButton>
                            <ToggleButton value="6" aria-label="Duminica">Du</ToggleButton>
                        </ToggleButtonGroup>
                    </FormControl>

                    <FormControl sx={{width: '100%', mb: 2}}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={roLocale}>
                            <DatePicker
                                label={"Data inceput"}
                                value={cloneFormDestinationIntervalBegin}
                                onChange={(newValue) => {
                                    setCloneFormDestinationIntervalBegin(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </FormControl>
                    <FormControl sx={{width: '100%', mb: 2}}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={roLocale}>
                            <DatePicker
                                label="Data sfarsit"
                                value={cloneFormDestinationIntervalEnd}
                                onChange={(newValue) => {
                                    setCloneFormDestinationIntervalEnd(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </FormControl>
                    <FormControl sx={{width: '100%', mb: 2}}>
                        <InputLabel id="cloneFormDestinationStaffMemberIdLabel">Suprascrie instructor</InputLabel>
                        <Select
                            labelId="cloneFormDestinationStaffMemberIdLabel"
                            id="cloneFormDestinationStaffMemberId"
                            value={cloneFormDestinationStaffMemberId}
                            label="Instructor"
                            onChange={(event: SelectChangeEvent) => {
                                setCloneFormDestinationStaffMemberId(event.target.value as string);
                            }}
                        >
                            <MenuItem value=''>( alege )</MenuItem>
                            {staffMembers.map((staffMember: StaffMember) => (
                                <MenuItem key={staffMember.id} value={staffMember.id}>{staffMember.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{width: '100%', mb: 2}}>
                        <InputLabel id="cloneFormDestinationLocationIdLabel">Locatie destinatie</InputLabel>
                        <Select
                            labelId="cloneFormDestinationLocationIdLabel"
                            id="cloneFormDestinationLocationId"
                            value={cloneFormDestinationLocationId}
                            label="Locatie destinatie"
                            onChange={(event: SelectChangeEvent) => {
                                setCloneFormDestinationLocationId(event.target.value as string);
                            }}
                        >
                            <MenuItem value=''>( alege )</MenuItem>
                            {locations.map((location: Location) => (
                                <MenuItem key={location.id} value={location.id}>{location.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {/*<FormControl sx={{width: '100%'}} variant="outlined">*/}
                    {/*    <FormControlLabel control={<Checkbox*/}
                    {/*        checked={cloneFormOverwrite}*/}
                    {/*        onClick={() => setCloneFormOverwrite(!cloneFormOverwrite)}*/}
                    {/*    />} label="Suprascrie peste clase deja existente"/>*/}
                    {/*</FormControl>*/}

                    <FormControl sx={{width: '100%', mb: 2}}>
                        <Button variant="contained" onClick={onCloneClick}>Cloneaza</Button>
                    </FormControl>

                </Box>
            </Drawer>

            <Drawer
                anchor='right'
                open={editDrawerShown}
                onClose={onEditDrawerClose}
            >
                <Typography component="h2" variant="h6" color="primary" px={2} py={1}>
                    Editereaza clase
                </Typography>
                <Box sx={{width: 420, px: 2}}>
                    <FormControl sx={{width: '100%', mb: 2}}>
                        <InputLabel id="formTrainingClassIdLabel">Clasa</InputLabel>
                        <Select
                            labelId="formTrainingClassIdLabel"
                            id="formTrainingClassId"
                            value={editFormTrainingClassId}
                            label="Clasa"
                            onChange={(event: SelectChangeEvent) => {
                                setEditFormTrainingClassId(event.target.value as string);
                            }}
                        >
                            <MenuItem value=''>( lasa neschimbata )</MenuItem>
                            {trainingClasses.map((trainingClass: TrainingClass) => (
                                <MenuItem key={trainingClass.id}
                                          value={trainingClass.id}>{trainingClass.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{width: '100%', mb: 2}}>
                        <InputLabel id="formLocationIdLabel">Locatie</InputLabel>
                        <Select
                            labelId="formLocationIdLabel"
                            id="formLocationId"
                            value={editFormLocationId}
                            label="Locatie"
                            onChange={(event: SelectChangeEvent) => {
                                setEditFormLocationId(event.target.value as string);
                            }}
                        >
                            <MenuItem value=''>( lasa neschimbata )</MenuItem>
                            {locations.map((location: Location) => (
                                <MenuItem key={location.id} value={location.id}>{location.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{width: '100%', mb: 2}}>
                        <InputLabel id="formStaffMemberIdLabel">Instructor</InputLabel>
                        <Select
                            labelId="formStaffMemberIdLabel"
                            id="formStaffMemberId"
                            value={editFormStaffMemberId}
                            label="Instructor"
                            onChange={(event: SelectChangeEvent) => {
                                setEditFormStaffMemberId(event.target.value as string);
                            }}
                        >
                            <MenuItem value=''>( lasa neschimbat )</MenuItem>
                            {staffMembers.map((staffMember: StaffMember) => (
                                <MenuItem key={staffMember.id} value={staffMember.id}>{staffMember.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{width: 190, mb: 2, mr: 1}}>
                        <TextField
                            id="outlined-basic"
                            label="Capacitate maxima"
                            variant="outlined"
                            value={editFormMaxCapacity}
                            onChange={(event) => {
                                setEditFormMaxCapacity(event.target.value as string);
                            }}
                        />
                    </FormControl>
                    <FormControl sx={{width: 190, mb: 2}}>
                        <TextField
                            id="outlined-basic"
                            label="Lista asteptare"
                            variant="outlined"
                            value={editFormWaitingListSize}
                            onChange={(event) => {
                                setEditFormWaitingListSize(event.target.value as string);
                            }}
                        />
                    </FormControl>
                    <FormControl sx={{width: '100%', mb: 2}}>
                        <Button variant="contained" onClick={onSaveClick}>Salveaza</Button>
                    </FormControl>

                </Box>
            </Drawer>

            <Dialog
                open={deleteDialogOpen}
                onClose={onDeleteDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirmare pentru stergere"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Sigur vrei sa stergi evenimentele selectate?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onDeleteDialogNoClick} autoFocus>Nu</Button>
                    <Button onClick={onDeleteDialogYesClick}>Da</Button>
                </DialogActions>
            </Dialog>

        </Box>
    );
}