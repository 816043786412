import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import MainMenu, {DrawerWidth} from "../../fragments/mainmenu/MainMenu";
import {DataGrid, GridRenderCellParams, GridRowModel} from "@mui/x-data-grid";
import InvoiceService, {InvoiceFilters} from "./InvoiceService";
import {dateToDateTimeStr} from "../../util/DateUtil";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid, InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent
} from "@mui/material";
import TextField from "@mui/material/TextField";

const drawerWidth = DrawerWidth;

interface RowsState {
    page: number;
    pageSize: number;
}

export default function InvoicePage() {
    const [rowsState, setRowsState] = React.useState<RowsState>({
        page: 0,
        pageSize: 5,
    });

    const [id, setId] = React.useState('');
    const [paymentId, setPaymentId] = React.useState('');
    const [number, setNumber] = React.useState('');
    const [series, setSeries] = React.useState('');
    const [generated, setGenerated] = React.useState('');
    const [downloaded, setDownloaded] = React.useState('');
    const [emailSent, setEmailSent] = React.useState('');

    const [rowCount, setRowCount] = React.useState<number | undefined>(undefined);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [data, setData] = React.useState<GridRowModel[]>([]);

    const [acceptDialogOpen, setAcceptDialogOpen] = React.useState(false);
    const [acceptDialogId, setAcceptDialogId] = React.useState(0);

    const onApproveClick = (id: number) => {
        setAcceptDialogId(id);
        setAcceptDialogOpen(true);
    }

    const onAcceptDialogClose = () => {
        setAcceptDialogOpen(false);
    }

    const onAcceptDialogNoClick = () => {
        setAcceptDialogOpen(false);
    }

    const onAcceptDialogYesClick = () => {
        setAcceptDialogOpen(false);
        InvoiceService.approve(acceptDialogId)
            .then(fetchRows);
    }

    const fetchRows = () => {
        let active = true;

        setIsLoading(true);
        setRowCount(undefined);

        const invoiceFilters = new InvoiceFilters(
            id, paymentId,
            number, series,
            generated, downloaded, emailSent,
            rowsState.page,
            rowsState.pageSize);
        InvoiceService.getList(invoiceFilters).then(response => {
            if (!active) {
                return;
            }
            setData(response.items);
            setIsLoading(false);
            setRowCount(response.totalItems);
        });

        return () => {
            active = false;
        };
    };

    React.useEffect(fetchRows, [id, paymentId, number, series, generated, downloaded, emailSent, rowsState]);

    const [rowCountState, setRowCountState] = React.useState(rowCount || 0);

    React.useEffect(() => {
        setRowCountState((prevRowCountState) =>
            rowCount !== undefined ? rowCount : prevRowCountState,
        );
    }, [rowCount, setRowCountState]);

    const columns = [
        {
            field: "id",
            headerName: "ID",
            width: 100,
        },
        {
            field: "paymentId",
            headerName: "ID Plata",
            width: 100,
        },
        {
            field: "createdAt",
            headerName: "Date",
            width: 250,
            editable: false,
            renderCell: (params: GridRenderCellParams<Date>) => dateToDateTimeStr(params.value),
        },
        {
            field: "number",
            headerName: "Numar",
            width: 100,
            editable: false,
        },
        {
            field: "series",
            headerName: "Serie",
            width: 100,
            editable: false,
        },
        {
            field: "generated",
            headerName: "Generata",
            width: 100,
            editable: false,
        },
        {
            field: "downloaded",
            headerName: "Descarcata",
            width: 100,
            editable: false,
        },
        {
            field: "emailSent",
            headerName: "Email trimis",
            width: 100,
            editable: false,
        },
        // {
        //     field: "status",
        //     headerName: "Actiuni",
        //     width: 100,
        //     editable: false,
        //     renderCell: (params: GridRenderCellParams<InvoiceStatus>) => {
        //         return params.row.isPendingOP() ? (
        //             <strong>
        //                 <Button
        //                     variant="contained"
        //                     color="primary"
        //                     size="small"
        //                     style={{ marginLeft: 16 }}
        //                     onClick={() => onApproveClick(params.row.id)}
        //                 >
        //                     Aproba
        //                 </Button>
        //             </strong>
        //         ) : ''
        //     },
        // },
    ];

    return (
        <Box sx={{display: 'flex'}}>
            <MainMenu/>
            <Box
                component="main"
                sx={{backgroundColor: '#f7f7f7', flexGrow: 1, p: 3, width: {sm: `calc(100% - ${drawerWidth}px)`}}}
            >
                <Toolbar/>

                <Grid container spacing={2}>
                    <Grid item xs={4} sm={4} md={2} lg={1}>
                        <FormControl sx={{width: '100%'}}>
                            <TextField
                                label="ID Factura"
                                variant="outlined"
                                value={id}
                                onChange={(event) => {
                                    setId(event.target.value)
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={4} md={2} lg={1}>
                        <FormControl sx={{width: '100%'}}>
                            <TextField
                                label="ID Plata"
                                variant="outlined"
                                value={paymentId}
                                onChange={(event) => {
                                    setPaymentId(event.target.value)
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={2}>
                        <FormControl sx={{width: '100%'}}>
                            <TextField
                                label="Numar"
                                variant="outlined"
                                value={number}
                                onChange={(event) => {
                                    setNumber(event.target.value)
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={2}>
                        <FormControl sx={{width: '100%'}}>
                            <TextField
                                label="Serie"
                                variant="outlined"
                                value={series}
                                onChange={(event) => {
                                    setSeries(event.target.value)
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={2}>
                        <FormControl sx={{width: '100%'}}>
                            <InputLabel id="generated-label">Generata</InputLabel>
                            <Select value={generated} label="Generat" labelId='generated-label'
                                onChange={(event: SelectChangeEvent) => setGenerated(event.target.value)}>
                                <MenuItem value={undefined}>(generat)</MenuItem>
                                <MenuItem value={'false'}>nu</MenuItem>
                                <MenuItem value={'true'}>da</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={2}>
                        <FormControl sx={{width: '100%'}}>
                            <InputLabel id="downloaded-label">Descarcata</InputLabel>
                            <Select value={downloaded} label="Descarcat" labelId='downloaded-label'
                                onChange={(event: SelectChangeEvent) => setDownloaded(event.target.value)}>
                                <MenuItem value={undefined}>(generat)</MenuItem>
                                <MenuItem value={'false'}>nu</MenuItem>
                                <MenuItem value={'true'}>da</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={2}>
                        <FormControl sx={{width: '100%'}}>
                            <InputLabel id="emailSent-label">Email trimis</InputLabel>
                            <Select value={emailSent} label="Email trimis" labelId='emailSent-label'
                                onChange={(event: SelectChangeEvent) => setEmailSent(event.target.value)}>
                                <MenuItem value={undefined}>(generat)</MenuItem>
                                <MenuItem value={'false'}>nu</MenuItem>
                                <MenuItem value={'true'}>da</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <div style={{ height: 400, width: '100%' }}>
                    <DataGrid
                        columns={columns}
                        rows={data}
                        rowCount={rowCountState}
                        loading={isLoading}
                        rowsPerPageOptions={[5, 10, 20, 50, 100]}
                        pagination
                        {...rowsState}
                        paginationMode="server"
                        onPageChange={(page) => setRowsState((prev) => ({ ...prev, page }))}
                        onPageSizeChange={(pageSize) =>
                            setRowsState((prev) => ({ ...prev, pageSize }))
                        }
                    />
                </div>

            </Box>

            <Dialog
                open={acceptDialogOpen}
                onClose={onAcceptDialogClose}
                aria-labelledby="accept-dialog-title"
                aria-describedby="accept-dialog-description"
            >
                <DialogTitle id="accept-dialog-title">
                    {"Confirmare pentru aprobare OP"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="accept-dialog-description">
                        Doresti sa aprobi plata prin OP?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onAcceptDialogNoClick} autoFocus>Nu</Button>
                    <Button onClick={onAcceptDialogYesClick}>Da</Button>
                </DialogActions>
            </Dialog>

        </Box>
    );
}