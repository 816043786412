export default class ClientReportItem {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    clientSubscriptionId: number;
    clientSubscriptionName: string;
    createdAt: string;
    remainingDays: number;
    totalDays: number;
    beginsAt: string;
    endsAt: string;
    paymentId: number;
    subtotal: number;
    discountAmount: number;
    total: number;
    totalBookings: number;
    upcomingBookings: number;
    showedUpBookings: number;
    earlyCancelBookings: number;
    lateCancelBookings: number;
    totalSuspensions: number;

    constructor(item: any) {
        this.id = item.id;
        this.firstName = item.firstName;
        this.lastName = item.lastName;
        this.email = item.email;
        this.clientSubscriptionId = item.clientSubscriptionId;
        this.clientSubscriptionName = item.clientSubscriptionName;
        this.createdAt = item.createdAt;
        this.remainingDays = item.remainingDays;
        this.totalDays = item.totalDays;
        this.beginsAt = item.beginsAt;
        this.endsAt = item.endsAt;
        this.paymentId = item.paymentId;
        this.subtotal = item.subtotal;
        this.discountAmount = item.discountAmount;
        this.total = item.total;
        this.totalBookings = item.totalBookings;
        this.upcomingBookings = item.upcomingBookings;
        this.showedUpBookings = item.showedUpBookings;
        this.earlyCancelBookings = item.earlyCancelBookings;
        this.lateCancelBookings = item.lateCancelBookings;
        this.totalSuspensions = item.totalSuspensions;
    }
}