import {convertToDate} from "../../util/DateUtil";

export default class Suspension {
    id: number;
    beginsAt: Date;
    endsAt: Date;
    extendsClientSubscription: boolean;
    createdAt: Date;
    updatedAt: Date;

    constructor(suspension: any) {
        this.id = suspension.id;
        //@ts-ignore
        this.beginsAt = convertToDate(suspension.beginsAt);
        //@ts-ignore
        this.endsAt = convertToDate(suspension.endsAt);
        this.extendsClientSubscription = suspension.extendsClientSubscription;
        //@ts-ignore
        this.createdAt = convertToDate(suspension.createdAt);
        //@ts-ignore
        this.updatedAt = convertToDate(suspension.updatedAt);
    }
}