import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import MainMenu, {DrawerWidth} from "../../fragments/mainmenu/MainMenu";
import TextField from '@mui/material/TextField';
import DateRangePicker, {DateRange} from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import roLocale from 'date-fns/locale/ro';
import {
    Backdrop,
    Button,
    ButtonGroup,
    Card, CardContent, CardActions, CardMedia,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Drawer,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import CalendarService, {EventFilters} from "./CalendarService";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import LocationService from "../location/LocationService";
import Location from "../location/Location";
import CalendarEvent from "./CalendarEvent";
import {dateToDateStr, dateToDay, dateToTimeStr} from "../../util/DateUtil";
import TrainingClassService from "../trainingclass/TrainingClassService";
import TrainingClass from "../trainingclass/TrainingClass";
import {useSnackbar, VariantType} from "notistack";
import ClientService from "../client/ClientService";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import BookingCreateForm from "./BookingCreateForm";
import {BookingStatus} from "./BookingStatus";
import ClientListItem from "../client/ClientListItem";
import StaffMember from "../staffmember/StaffMember";
import StaffMemberService from "../staffmember/StaffMemberService";
import ClientSelector from "../../fragments/ClientSelector";
import ClientPhoto from "../client/ClientPhoto";

const drawerWidth = DrawerWidth;

const today = new Date();
const oneWeekFromNow = new Date();
oneWeekFromNow.setDate(oneWeekFromNow.getDate() + 7);

export default function CheckinPage() {
    const {enqueueSnackbar} = useSnackbar();

    const [loadingWheelVisible, setLoadingWheelVisible] = React.useState(false);
    const [period, setPeriod] = React.useState<DateRange<Date>>([today, oneWeekFromNow]);
    const [trainingClassId, setTrainingClassId] = React.useState('');
    const [locationId, setLocationId] = React.useState('');
    const [staffMemberId, setStaffMemberId] = React.useState('');
    const [trainingClasses, setTrainingClasses] = React.useState<TrainingClass[]>([]);
    const [locations, setLocations] = React.useState<Location[]>([]);
    const [staffMembers, setStaffMembers] = React.useState<StaffMember[]>([]);
    const [clients, setClients] = React.useState<Map<number, ClientListItem>>(new Map());
    const [eventsByDay, setEventsByDay] = React.useState<Map<string, CalendarEvent[]>>(new Map());

    const [eventId, setEventId] = React.useState('');
    const [addClientId, setAddClientId] = React.useState('');
    const [addDrawerShown, setAddDrawerShown] = React.useState(false);

    const [deleteBookingId, setDeleteBookingId] = React.useState<number>(0);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

    const [addDialogOpen, setAddDialogOpen] = React.useState(false);

    const getTrainingClass = (id: number) => trainingClasses.filter(value => value.id == id)[0]
    const getLocation = (id: number) => locations.filter(value => value.id == id)[0]

    const getClients = (clientIds: number[]) => {
        ClientService.getByIdIn(clientIds)
            .then((response) => {
                const clientMap: Map<number, ClientListItem> = new Map();
                response.map(client => clientMap.set(client.id, client));
                setClients(clientMap);
            });
    }

    const getEvents = () => {
        if (null == period[0] || null == period[1]) {
            return;
        }
        setLoadingWheelVisible(true);
        let eventFilters = new EventFilters(period[0], period[1], trainingClassId, locationId, staffMemberId, [], [], true);
        CalendarService.getEvents(eventFilters)
            .then((events) => {
                const clientIdMap: Set<number> = new Set;
                setLoadingWheelVisible(false);
                const eventsByDay = new Map<string, CalendarEvent[]>();
                events.forEach((event: CalendarEvent) => {
                    event.bookings.map(value => clientIdMap.add(value.clientId));
                    const day: string = dateToDay(event.beginsAt);
                    if (!eventsByDay.has(day)) {
                        eventsByDay.set(day, []);
                    }
                    const dailyEvents: CalendarEvent[] | undefined = eventsByDay.get(day);
                    // @ts-ignore
                    dailyEvents.push(event);
                });
                setEventsByDay(eventsByDay);
                const clientIds: number[] = [];
                clientIdMap.forEach(value => clientIds.push(value));
                getClients(clientIds);
            })
            .catch(() => {
                setLoadingWheelVisible(false)
            });
    }

    React.useEffect(() => {
        TrainingClassService.getList()
            .then(trainingClasses => setTrainingClasses(trainingClasses));
        LocationService.getList()
            .then(locations => setLocations(locations));
        StaffMemberService.getList()
            .then(staffMembers => setStaffMembers(staffMembers));
    }, []);

    React.useEffect(() => {
        getEvents();
    }, [trainingClassId, locationId, staffMemberId, period]);

    const onAddDialogClose = () => {
        setAddDialogOpen(false);
    }

    const onAddDialogNoClick = () => {
        setAddDialogOpen(false);
        setAddDrawerShown(false);
    }

    const onAddDialogYesClick = () => {
        setAddDialogOpen(false);
        const form = new BookingCreateForm(addClientId, eventId ? eventId : '');
        createBooking(form);
    }

    const onDeleteDialogClose = () => {
        setDeleteDialogOpen(false);
    }

    const onDeleteDialogNoClick = () => {
        setDeleteDialogOpen(false);
    }

    const onDeleteDialogYesClick = () => {
        setDeleteDialogOpen(false);
        CalendarService.deleteBooking(deleteBookingId)
            .then(() => {
                getEvents();
            });
    }

    const onDeleteClick = (id: string) => {
        setDeleteBookingId(Number.parseInt(id));
        setDeleteDialogOpen(true);
    }

    const onAddClick = () => {
        const form = new BookingCreateForm(addClientId, eventId ? eventId : '');
        CalendarService.hasValidSubscriptionForEvent(form).then(
            response => {
                response.json().then(hasSubscription => {
                    if (hasSubscription) {
                        createBooking(form);
                    } else {
                        setAddDialogOpen(true);
                    }
                })
            }
        );
    }

    function createBooking(form: BookingCreateForm) {
        CalendarService.createBooking(form)
            .then((response) => {
                if (200 === response.status) {
                    setAddDrawerShown(false);
                    setAddClientId('');
                    setLoadingWheelVisible(false);

                    const variant: VariantType = 'success';
                    enqueueSnackbar('Succes', {variant});
                    getEvents();
                } else {
                    setLoadingWheelVisible(false);
                    response.json().then(json => {
                        const variant: VariantType = 'error';
                        enqueueSnackbar(json.message, {variant});
                    });
                }
            });
    }

    function onClientImageChange(event: React.ChangeEvent<HTMLInputElement>) {
        let fileUploadInput: HTMLInputElement = event.target;
        // @ts-ignore
        const file: File = fileUploadInput?.files[0];
        // @ts-ignore
        const image: HTMLImageElement = fileUploadInput.parentNode?.parentNode?.querySelector('img');
        // @ts-ignore
        image.src = URL.createObjectURL(file);

        let clientId: number = Number.parseInt(fileUploadInput.dataset.clientId ? fileUploadInput.dataset.clientId : '0');
        let fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            //@ts-ignore
            let result: string = fileReader.result;
            let b64encoded = result.slice(result.indexOf(',') + 1);
            let clientPhoto = new ClientPhoto(clientId, b64encoded, file.type, file.size);
            ClientService.setPhoto(clientPhoto);
        }

    }

    const onAddDrawerClose = (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setAddDrawerShown(false);
    };

    const onAddButtonClick = (id: string) => {
        setAddDrawerShown(true);
        setEventId(id);
    };

    let tables: JSX.Element;

    const onStatusChangeClick = (id: number, status: BookingStatus) => {
        CalendarService.setBookingStatus(id, status)
            .then(() => {
                getEvents();
            });
    }

    tables =
        <>
            {Array.from(eventsByDay.values()).map((dailyEvents: CalendarEvent[]) => (
                <>
                    <Typography component="h2" variant="h6" color="primary" pt={2}
                                key={'day_' + dailyEvents[0].beginsAt.getTime()}>
                        {dateToDateStr(dailyEvents[0].beginsAt)}
                    </Typography>

                    {dailyEvents.map((event) => (
                        <Grid item xs={12} md={12} lg={12} pt={2} key={'event_' + event.id}>
                            <Typography component="div" variant="h5" px={2}>
                                <Button onClick={() => onAddButtonClick(event.id.toString())} variant="outlined"
                                        color="success"
                                        value={event.id} sx={{mr: 1}}>
                                    <AddCircleIcon fontSize="small"/>
                                </Button>
                                {dateToTimeStr(event.beginsAt)} - {dateToTimeStr(event.endsAt)}
                                &nbsp;:: {getTrainingClass(event.trainingClassId).name}
                                &nbsp;:: {getLocation(event.locationId).name}
                                &nbsp;:: ( {event.activeBookings().length} / {event.maxCapacity} )
                            </Typography>

                            {event.checkinVisibleBookings().map(booking => (
                                <Card key={'booking_' + booking.id}
                                    sx={{ maxWidth: 500, display: 'flex', margin: 1, float: 'left' }}>
                                    <Box sx={{ width: 151 }}>
                                        <div style={{position: 'relative'}}>
                                            <Button
                                                style={{width: 151, height: 151, position: 'absolute'}}
                                                component="label"
                                            >
                                                <input
                                                    data-client-id={booking.clientId}
                                                    type="file"
                                                    accept="image/*"
                                                    hidden
                                                    onChange={(event) => onClientImageChange(event)}
                                                />
                                            </Button>
                                            <img src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D" onLoad={(event) => {
                                                let htmlImageElement = event.target as HTMLImageElement;
                                                if (htmlImageElement.dataset.loaded) {
                                                    return;
                                                }
                                                ClientService.getPhoto(booking.clientId).then(value => {
                                                    htmlImageElement.src = "data:" + value.contentType + ";base64," + value.contentData;
                                                    //@ts-ignore
                                                    htmlImageElement.dataset.loaded = true;
                                                })
                                            }} style={{ maxWidth: 151, maxHeight: 151}} />
                                        </div>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <CardContent sx={{ flex: '1 0 auto' }}>
                                            <Typography component="div" variant="h5">
                                                {clients.get(booking.clientId)?.firstName + " " + clients.get(booking.clientId)?.lastName}
                                            </Typography>
                                            <Typography variant="subtitle1" color="text.secondary" component="div">
                                                {booking.clientSubscription ? booking.clientSubscription.name : '(fara abonament)'}
                                            </Typography>
                                        </CardContent>
                                        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                                            <Button color="success" sx={{ mr: 1 }} variant={booking.status == BookingStatus.SHOWED_UP ? 'contained' : 'text'}
                                                    onClick={() => onStatusChangeClick(booking.id, BookingStatus.SHOWED_UP)}><CheckCircleIcon/></Button>
                                            <Button color="error" variant={booking.status == BookingStatus.LATE_CANCEL ? 'contained' : 'text'}
                                                    onClick={() => onStatusChangeClick(booking.id, BookingStatus.LATE_CANCEL)}><DoDisturbOnIcon/></Button>
                                            <Button color="error" variant='text'
                                                    onClick={() => onDeleteClick(booking.id.toString())}>
                                                <DeleteIcon/>
                                            </Button>
                                        </Box>
                                    </Box>
                                </Card>
                            ))}
                            <div style={{ clear: 'both' }}></div>
                        </Grid>
                    ))}
                </>
            ))}
        </>;

    const hasStaffMembers = staffMembers.length > 0;

    return (
        <Box sx={{display: 'flex'}}>
            <MainMenu/>
            <Box
                component="main"
                sx={{backgroundColor: '#f7f7f7', flexGrow: 1, p: 3, width: {sm: `calc(100% - ${drawerWidth}px)`}}}
            >
                <Toolbar/>


                {loadingWheelVisible
                    ? (<Backdrop sx={{color: '#fff'}}
                                 open={loadingWheelVisible}>
                        <CircularProgress color="inherit"/>
                    </Backdrop>) : ''}

                <LocalizationProvider dateAdapter={AdapterDateFns} locale={roLocale}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={hasStaffMembers ? 3 : 4}>
                            <FormControl>
                                <DateRangePicker
                                    startText="De pe"
                                    endText="Pana pe"
                                    value={period}
                                    onChange={(newValue) => {
                                        setPeriod(newValue);
                                    }}
                                    renderInput={(startProps, endProps) => (
                                        <>
                                            <TextField {...startProps} sx={{mr: 2}}/>
                                            <TextField {...endProps}/>
                                        </>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={hasStaffMembers ? 3 : 4}>
                            <FormControl sx={{width: '100%'}}>
                                <InputLabel id="label1">Clasa</InputLabel>
                                <Select
                                    labelId="label1"
                                    id="select1"
                                    value={trainingClassId}
                                    label="Clasa"
                                    onChange={(event: SelectChangeEvent) => {
                                        setTrainingClassId(event.target.value as string);
                                    }}
                                >
                                    <MenuItem value=''>( toate )</MenuItem>
                                    {trainingClasses.map((trainingClass: TrainingClass) => (
                                        <MenuItem key={trainingClass.id}
                                                  value={trainingClass.id}>{trainingClass.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={hasStaffMembers ? 3 : 4}>
                            <FormControl sx={{width: '100%'}}>
                                <InputLabel id="locationIdLabel">Locatie</InputLabel>
                                <Select
                                    labelId="locationIdLabel"
                                    id="locationId"
                                    value={locationId}
                                    label="Locatie"
                                    onChange={(event: SelectChangeEvent) => {
                                        setLocationId(event.target.value as string);
                                    }}
                                >
                                    <MenuItem value=''>( toate )</MenuItem>
                                    {locations.map((location: Location) => (
                                        <MenuItem key={location.id} value={location.id}>{location.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {hasStaffMembers ? (<Grid item xs={12} sm={12} md={6} lg={3}>
                            <FormControl sx={{width: '100%'}}>
                                <InputLabel id="staffMemberIdLabel">Instructor</InputLabel>
                                <Select
                                    labelId="staffMemberIdLabel"
                                    id="staffMemberId"
                                    value={staffMemberId}
                                    label="Instructor"
                                    onChange={(event: SelectChangeEvent) => {
                                        setStaffMemberId(event.target.value as string);
                                    }}
                                >
                                    <MenuItem value=''>( toate )</MenuItem>
                                    {staffMembers.map((staffMember: StaffMember) => (
                                        <MenuItem key={staffMember.id}
                                                  value={staffMember.id}>{staffMember.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>) : ''}
                    </Grid>
                </LocalizationProvider>

                {tables}

            </Box>

            <Drawer
                anchor='right'
                open={addDrawerShown}
                onClose={onAddDrawerClose}
            >
                <Typography component="h2" variant="h6" color="primary" px={2} py={1}>
                    Programeaza client
                </Typography>
                <Box sx={{width: 420, px: 2}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl sx={{width: '100%'}}>
                                <ClientSelector value={"2"} onChange={(newValue: string | null) => setAddClientId(newValue ? newValue : '')} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl sx={{width: '100%'}}>
                                <Button variant="contained" onClick={onAddClick}>Adauga programare</Button>
                            </FormControl>
                        </Grid>
                    </Grid>

                </Box>
            </Drawer>

            <Dialog
                open={deleteDialogOpen}
                onClose={onDeleteDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirmare pentru stergere"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Sigur vrei sa stergi aceasta rezervare?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onDeleteDialogNoClick} autoFocus>Nu</Button>
                    <Button onClick={onDeleteDialogYesClick}>Da</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={addDialogOpen}
                onClose={onAddDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirmare pentru adaugare"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Clientul nu are abonament activ la data respectiva. Doresti sa creezi programarea?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onAddDialogNoClick} autoFocus>Nu</Button>
                    <Button onClick={onAddDialogYesClick}>Da</Button>
                </DialogActions>
            </Dialog>

        </Box>
    );
}