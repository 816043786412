import BackendService from "../../config/BackendService";
import CalendarEvent from "./CalendarEvent";
import CalendarEventGenerateForm from "./CalendarEventGenerateForm";
import CalendarEventCloneWeekForm from "./CalendarEventCloneWeekForm";
import CalendarEventEditForm from "./CalendarEventEditForm";
import BookingCreateForm from "./BookingCreateForm";
import {BookingStatus} from "./BookingStatus";
import CalendarEventClient from "./CalendarEventClient";
import {ReportFilters} from "../report/ReportFilters";
import BookingReportItem from "./BookingReportItem";

export class EventFilters {
    trainingClassId:  string | null;
    locationId:  string | null;
    staffMemberId: string | null;
    periodBegin: Date | null;
    periodEnd: Date | null;
    weekdays: string[];
    beginHours: string[];
    fillClientSubscriptions: boolean;

    constructor(periodBegin: Date | null,
                periodEnd: Date | null,
                trainingClassId: string | null,
                locationId: string | null,
                staffMemberId: string | null,
                weekdays: string[],
                beginHours: string[],
                fillClientSubscriptions: boolean) {
        this.periodBegin = periodBegin;
        this.periodEnd = periodEnd;
        this.trainingClassId = trainingClassId;
        this.locationId = locationId;
        this.staffMemberId = staffMemberId;
        this.weekdays = weekdays;
        this.beginHours = beginHours;
        this.fillClientSubscriptions = fillClientSubscriptions;
    }

    toUrlSearchParams(): URLSearchParams {
        const params = new URLSearchParams();
        params.append("periodBegin", this.periodBegin ? this.periodBegin.toISOString() : '');
        params.append("periodEnd", this.periodEnd ? this.periodEnd.toISOString() : '');
        params.append("trainingClassIds", this.trainingClassId ? this.trainingClassId : '');
        params.append("locationIds", this.locationId ? this.locationId : '');
        params.append("staffMemberIds", this.staffMemberId ? this.staffMemberId.toString() : '');
        params.append("weekdays", this.weekdays ? this.weekdays.toString() : '');
        params.append("beginHours", this.beginHours ? this.beginHours.toString() : '');
        params.append("fillClientSubscriptions", this.fillClientSubscriptions ? '1' : '0');
        return params;
    }
}

export default class CalendarService {
    static getEvents = (filters: EventFilters): Promise<CalendarEvent[]> =>
        BackendService.getJson("/api/v1/calendar", filters.toUrlSearchParams())
            .then(events => events.map((event: any) => new CalendarEvent(event)));

    static generateEvents(form: CalendarEventGenerateForm): Promise<Response> {
        return BackendService.postJson("/api/v1/calendar/generate", form);
    }

    static cloneWeekEvents(form: CalendarEventCloneWeekForm): Promise<Response> {
        return BackendService.postJson("/api/v1/calendar/clone-week", form);
    }

    static save(form: CalendarEventEditForm): Promise<Response> {
        return BackendService.postJson("/api/v1/calendar/save", form);
    }

    static delete(ids: number[]): Promise<Response> {
        return BackendService.postJson("/api/v1/calendar/delete", ids);
    }

    static getEvent = (eventId: number): Promise<CalendarEvent> => {
        return BackendService.getJson("/api/v1/calendar/get/" + eventId)
            .then(data => new CalendarEvent(data));
    }

    static hasValidSubscriptionForEvent(form: BookingCreateForm): Promise<Response> {
        return BackendService.postJson("/api/v1/calendar/has-valid-subscription", form);
    }

    static createBooking(form: BookingCreateForm): Promise<Response> {
        return BackendService.postJson("/api/v1/calendar/create-booking", form);
    }

    static deleteBooking = (id: number): Promise<Response> =>
        BackendService.postJson("/api/v1/calendar/delete-bookings", [id]);

    static setBookingStatus = (id: number, status: BookingStatus) =>
        BackendService.postJson("/api/v1/calendar/set-booking-status", {id: id, status: status});

    static getClientBookings = (clientId: number): Promise<CalendarEventClient[]> =>
        BackendService.getJson("/api/v1/calendar/client-bookings/" + clientId)
            .then(bookings => bookings.map((booking: any) => new CalendarEventClient(booking)));

    static getBookingsReport = (filters: ReportFilters): Promise<BookingReportItem[]> =>
        BackendService.getJson("/api/v1/calendar/bookings-report", filters.toUrlSearchParams())
            .then(bookings => bookings.map((item: any) => new BookingReportItem(item)));

}
