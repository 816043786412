export default class RegisteringClient {
    sessionId: string;
    step: number;
    email: string;
    verificationCode: string;
    firstName: string;
    lastName: string;
    mobilePhone: string;

    constructor(client: any) {
        this.sessionId = client.sessionId;
        this.step = client.step;
        this.email = client.email;
        this.verificationCode = client.verificationCode;
        this.firstName = client.firstName;
        this.lastName = client.lastName;
        this.mobilePhone = client.mobilePhone;
    }
}