import {SubscriptionType} from "../subscription/SubscriptionType";
import {PaymentMethod} from "./PaymentMethod";

export default class SalesReportItem {
    createdAt: string;
    id: number;
    clientId: number;
    clientName: string;
    subscriptionName: string;
    subtotal: number;
    discountAmount: number;
    total: number;
    paymentMethod: PaymentMethod;
    coupon: string;

    constructor(item: any) {
        this.createdAt = item.createdAt;
        this.id = item.id;
        this.clientId = item.clientId;
        this.clientName = item.clientName;
        this.subscriptionName = item.subscriptionName;
        this.subtotal = item.subtotal;
        this.discountAmount = item.discountAmount;
        this.total = item.total;
        this.paymentMethod = item.paymentMethod;
        this.coupon = item.coupon;
    }
}