export default class LocationForm {
    name: string;
    address: string;
    latitude: string;
    longitude: string;
    active: boolean;
    ordering: number;

    constructor(
        name: string,
        address: string,
        latitude: string,
        longitude: string,
        active: boolean,
        ordering: number
    ) {
        this.name = name;
        this.address = address;
        this.latitude = latitude;
        this.longitude = longitude;
        this.active = active;
        this.ordering = ordering;
    }
}