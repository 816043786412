import Client from "./Client";
import BackendService from "../../config/BackendService";
import ClientForm from "../client/ClientForm";
import RegisteringClient from "./RegisteringClient";
import ClientListItem from "./ClientListItem";
import ClientReportItem from "./ClientReportItem";
import {ReportFilters} from "../report/ReportFilters";
import ClientPhoto from "./ClientPhoto";

export class ClientFilters {
    id: string | null;
    searchText: string | null;
    page: number;
    perPage: number;

    constructor(id: string | null,
                searchText: string | null,
                page: number,
                perPage: number) {
        this.id = id;
        this.searchText = searchText;
        this.page = page;
        this.perPage = perPage;
    }

    toUrlSearchParams(): URLSearchParams {
        const params = new URLSearchParams();
        params.append("id", this.id ? this.id : '');
        params.append("searchText", this.searchText ? this.searchText : '');
        params.append("page", this.page.toString());
        params.append("perPage", this.perPage.toString());
        return params;
    }
}

export class ClientListResponse {
    items: Client[];
    totalItems: number;

    constructor(items: Client[], totalItems: number) {
        this.items = items;
        this.totalItems = totalItems;
    }
}

export default class ClientService {
    static getFilteredList(filters: ClientFilters): Promise<ClientListResponse> {
        return BackendService.getJson('/api/v1/client/filtered', filters.toUrlSearchParams())
            .then(response => {
                const items = response.content.map((item: any) => new Client(item));
                return new ClientListResponse(items, response.totalElements);
            });
    }

    static getList(): Promise<ClientListItem[]> {
        return BackendService.getJson('/api/v1/client')
            .then(events => events.map((event: any) => new ClientListItem(event)));
    }

    static getByIdIn(clientIds: number[]): Promise<ClientListItem[]> {
        return BackendService.getJson('/api/v1/client/by-id-in/' + clientIds.join(','))
            .then(events => events.map((event: any) => new ClientListItem(event)));
    }

    static getRegisteringList(): Promise<RegisteringClient[]> {
        return BackendService.getJson('/api/v1/client/registering')
            .then(events => events.map((event: any) => new RegisteringClient(event)));
    }

    static save(id: number | null, form: ClientForm): Promise<Response> {
        return (null == id)
            ? BackendService.postJson("/api/v1/client", form)
            : BackendService.putJson("/api/v1/client/" + id, form);
    }

    static delete(id: number): Promise<Response> {
        return BackendService.delete("/api/v1/client/" + id);
    }

    static getClient(id: number): Promise<Client> {
        return BackendService.getJson("/api/v1/client/" + id)
            .then(client => new Client(client));
    }

    static getAuthenticationData(id: string) {
        return BackendService.getText("/api/v1/client/act-as/" + id);
    }

    static getReportActiveClientsTotal(filters: ReportFilters): Promise<ClientReportItem[]> {
        return BackendService.getJson('/api/v1/client/report-active-clients-total', filters.toUrlSearchParams())
            .then(items => items.map((item: any) => new ClientReportItem(item)));
    }

    static getReportPromotionalEmailList(filters: ReportFilters): Promise<ClientReportItem[]> {
        return BackendService.getJson('/api/v1/client/report-promotional-email-list')
            .then(items => items.map((item: any) => new Client(item)));
    }

    static setPhoto(clientPhoto: ClientPhoto) {
        BackendService.postJson("/api/v1/client/set-photo", clientPhoto);
    }

    static deletePhoto(id: number) {
        BackendService.getJson("/api/v1/client/delete-photo/" + id)
    }

    static getPhoto(id: number): Promise<ClientPhoto> {
        return BackendService.getJson("/api/v1/client/get-photo/" + id)
            .then(data => data);
    }
}
