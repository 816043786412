export default class ClientListItem {
    id: number;
    firstName: string;
    lastName: string;
    email: string;

    constructor(client: any) {
        this.id = client.id;
        this.firstName = client.firstName;
        this.lastName = client.lastName;
        this.email = client.email;
    }
}