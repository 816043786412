import {BookingStatus} from "../calendar/BookingStatus";

export default class DemoSubscriptionReportItem {
    accountCreatedAt: string;
    demoSessionDate: string;
    clientId: number;
    clientName: string;
    hasBooked: string;
    bookingStatus: BookingStatus;
    staffMemberName: string;
    hasBoughtSubscription: string;
    clientSubscriptionCreatedAt: string;

    constructor(item: any) {
        this.accountCreatedAt = item.accountCreatedAt
        this.demoSessionDate = item.demoSessionDate
        this.clientId = item.clientId
        this.clientName = item.clientName
        this.hasBooked = item.hasBooked
        this.bookingStatus = item.bookingStatus
        this.staffMemberName = item.staffMemberName
        this.hasBoughtSubscription = item.hasBoughtSubscription
        this.clientSubscriptionCreatedAt = item.clientSubscriptionCreatedAt
    }
}