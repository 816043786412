import {
    Button,
    Drawer,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent, ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import TrainingClass from "../trainingclass/TrainingClass";
import Location from "../location/Location";
import StaffMember from "../staffmember/StaffMember";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import roLocale from "date-fns/locale/ro";
import {DatePicker, TimePicker} from "@mui/lab";
import TextField from "@mui/material/TextField";
import * as React from "react";
import CalendarEventGenerateForm from "./CalendarEventGenerateForm";
import CalendarService from "./CalendarService";
import {VariantType} from "notistack";
import TrainingClassService from "../trainingclass/TrainingClassService";
import LocationService from "../location/LocationService";
import StaffMemberService from "../staffmember/StaffMemberService";

export default function CalendarPageGenerateDrawer(params: any) {
    const [genFormTrainingClassId, setGenFormTrainingClassId] = React.useState('');
    const [genFormLocationId, setGenFormLocationId] = React.useState('');
    const [genFormStaffMemberId, setGenFormStaffMemberId] = React.useState('');
    const [genFormEventGenerationType, setGenFormEventGenerationType] = React.useState('0');
    const [genFormIntervalBegin, setGenFormIntervalBegin] = React.useState<Date | null>(new Date());
    const [genFormIntervalEnd, setGenFormIntervalEnd] = React.useState<Date | null>(null);
    const [genFormWeekdays, setGenFormWeekdays] = React.useState<string[]>(() => []);
    const [genFormBeginsAt, setGenFormBeginsAt] = React.useState<Date | null>(null);
    const [genFormEndsAt, setGenFormEndsAt] = React.useState<Date | null>(null);
    const [genFormMaxCapacity, setGenFormMaxCapacity] = React.useState('6');
    const [genFormWaitingListSize, setGenFormWaitingListSize] = React.useState('1');
    const [genFormMinimumBookingMinutesInterval, setGenFormMinimumBookingMinutesInterval] = React.useState('0');

    const [trainingClasses, setTrainingClasses] = React.useState<TrainingClass[]>([]);
    const [locations, setLocations] = React.useState<Location[]>([]);
    const [staffMembers, setStaffMembers] = React.useState<StaffMember[]>([]);

    React.useEffect(() => {
        TrainingClassService.getList()
            .then(trainingClasses => setTrainingClasses(trainingClasses));
        LocationService.getList()
            .then(locations => setLocations(locations));
        StaffMemberService.getList()
            .then(staffMembers => setStaffMembers(staffMembers));
    }, []);

    const onGenerateClick = () => {
        if (genFormIntervalBegin && genFormBeginsAt && genFormEndsAt) {
            const form = new CalendarEventGenerateForm(
                genFormTrainingClassId,
                genFormLocationId,
                genFormStaffMemberId,
                genFormEventGenerationType,
                genFormIntervalBegin,
                genFormIntervalEnd,
                genFormWeekdays,
                genFormBeginsAt,
                genFormEndsAt,
                genFormMaxCapacity,
                genFormWaitingListSize,
                genFormMinimumBookingMinutesInterval
            );
            CalendarService.generateEvents(form)
                .then((response) => {
                    if (200 === response.status) {

                        setGenFormTrainingClassId("");
                        setGenFormLocationId("");
                        setGenFormStaffMemberId("");
                        setGenFormEventGenerationType("0");
                        setGenFormIntervalBegin(new Date());
                        setGenFormIntervalEnd(null);
                        setGenFormWeekdays([]);
                        setGenFormBeginsAt(null);
                        setGenFormEndsAt(null);
                        setGenFormMaxCapacity("6");
                        setGenFormWaitingListSize("1");
                        setGenFormMinimumBookingMinutesInterval("0");

                        if ('function' == typeof params.onSuccess) {
                            params.onSuccess();
                        }
                    } else {
                        response.json().then(json => {
                            if ('function' == typeof params.onError) {
                                params.onError(json.message);
                            }
                        });
                    }
                });
        }
    }

    const onGenerateDrawerClose = (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        if ('function' == typeof params.onClose) {
            params.onClose();
        }
    };

    return (
        <Drawer
            anchor='right'
            open={true}
            onClose={onGenerateDrawerClose}
        >
            <Typography component="h2" variant="h6" color="primary" px={2} py={1}>
                Genereaza program
            </Typography>
            <Box sx={{width: 420, px: 2}}>
                <FormControl sx={{width: '100%', mb: 2}}>
                    <InputLabel id="formTrainingClassIdLabel">Clasa</InputLabel>
                    <Select
                        labelId="formTrainingClassIdLabel"
                        id="formTrainingClassId"
                        value={genFormTrainingClassId}
                        label="Clasa"
                        onChange={(event: SelectChangeEvent) => {
                            setGenFormTrainingClassId(event.target.value as string);
                        }}
                    >
                        <MenuItem value=''>( alege )</MenuItem>
                        {trainingClasses.map((trainingClass: TrainingClass) => (
                            <MenuItem key={trainingClass.id}
                                      value={trainingClass.id}>{trainingClass.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl sx={{width: '100%', mb: 2}}>
                    <InputLabel id="formLocationIdLabel">Locatie</InputLabel>
                    <Select
                        labelId="formLocationIdLabel"
                        id="formLocationId"
                        value={genFormLocationId}
                        label="Locatie"
                        onChange={(event: SelectChangeEvent) => {
                            setGenFormLocationId(event.target.value as string);
                        }}
                    >
                        <MenuItem value=''>( alege )</MenuItem>
                        {locations.map((location: Location) => (
                            <MenuItem key={location.id} value={location.id}>{location.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl sx={{width: '100%', mb: 2}}>
                    <InputLabel id="formStaffMemberIdLabel">Instructor</InputLabel>
                    <Select
                        labelId="formStaffMemberIdLabel"
                        id="formStaffMemberId"
                        value={genFormStaffMemberId}
                        label="Instructor"
                        onChange={(event: SelectChangeEvent) => {
                            setGenFormStaffMemberId(event.target.value as string);
                        }}
                    >
                        <MenuItem value=''>( alege )</MenuItem>
                        {staffMembers.map((staffMember: StaffMember) => (
                            <MenuItem key={staffMember.id} value={staffMember.id}>{staffMember.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl sx={{width: '100%', mb: 2}}>
                    <InputLabel id="formEventGenerationTypeLabel">Tip generare</InputLabel>
                    <Select
                        labelId="formEventGenerationTypeLabel"
                        id="formEventGenerationType"
                        value={genFormEventGenerationType}
                        label="Tip generare"
                        onChange={(event: SelectChangeEvent) => {
                            setGenFormEventGenerationType(event.target.value as string);
                        }}
                    >
                        <MenuItem value='0'>O singura zi</MenuItem>
                        <MenuItem value='1'>Saptamanal</MenuItem>
                    </Select>
                </FormControl>
                <FormControl sx={{width: '100%', mb: 2}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={roLocale}>
                        <DatePicker
                            label={'1' === genFormEventGenerationType ? "Data inceput" : "Data"}
                            value={genFormIntervalBegin}
                            onChange={(newValue) => {
                                setGenFormIntervalBegin(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </FormControl>
                {('1' === genFormEventGenerationType) ? (
                    <FormControl sx={{width: '100%', mb: 2}}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={roLocale}>
                            <DatePicker
                                label="Data sfarsit"
                                value={genFormIntervalEnd}
                                onChange={(newValue) => {
                                    setGenFormIntervalEnd(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </FormControl>
                ) : ''}
                {('1' === genFormEventGenerationType) ? (
                    <FormControl sx={{width: '100%', mb: 2}}>
                        <ToggleButtonGroup
                            value={genFormWeekdays}
                            onChange={(
                                event: React.MouseEvent<HTMLElement>,
                                newValues: string[],
                            ) => {
                                setGenFormWeekdays(newValues);
                            }}
                            aria-label="Zile din saptamana"
                            color="primary"
                        >
                            <ToggleButton value="0" aria-label="Luni">Lu</ToggleButton>
                            <ToggleButton value="1" aria-label="Marti">Ma</ToggleButton>
                            <ToggleButton value="2" aria-label="Miercuri">Mi</ToggleButton>
                            <ToggleButton value="3" aria-label="Joi">Jo</ToggleButton>
                            <ToggleButton value="4" aria-label="Vineri">Vi</ToggleButton>
                            <ToggleButton value="5" aria-label="Sambata">Sa</ToggleButton>
                            <ToggleButton value="6" aria-label="Duminica">Du</ToggleButton>
                        </ToggleButtonGroup>
                    </FormControl>
                ) : ''}
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={roLocale}>
                    <FormControl sx={{width: 190, mb: 2, mr: 1}}>
                        <TimePicker
                            value={genFormBeginsAt}
                            onChange={(newValue) => setGenFormBeginsAt(newValue)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </FormControl>
                    <FormControl sx={{width: 190, mb: 2}}>
                        <TimePicker
                            value={genFormEndsAt}
                            onChange={(newValue) => setGenFormEndsAt(newValue)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </FormControl>
                </LocalizationProvider>
                <FormControl sx={{width: 190, mb: 2, mr: 1}}>
                    <TextField
                        id="outlined-basic"
                        label="Capacitate maxima"
                        variant="outlined"
                        value={genFormMaxCapacity}
                        onChange={(event) => {
                            setGenFormMaxCapacity(event.target.value as string);
                        }}
                    />
                </FormControl>
                <FormControl sx={{width: 190, mb: 2}}>
                    <TextField
                        id="outlined-basic"
                        label="Lista asteptare"
                        variant="outlined"
                        value={genFormWaitingListSize}
                        onChange={(event) => {
                            setGenFormWaitingListSize(event.target.value as string);
                        }}
                    />
                </FormControl>
                <FormControl sx={{width: 190, mb: 2}}>
                    <TextField
                        id="outlined-basic"
                        label="Minute avans programare"
                        variant="outlined"
                        value={genFormMinimumBookingMinutesInterval}
                        onChange={(event) => {
                            setGenFormMinimumBookingMinutesInterval(event.target.value as string);
                        }}
                    />
                </FormControl>
                <FormControl sx={{width: '100%', mb: 2}}>
                    <Button variant="contained" onClick={onGenerateClick}>Genereaza</Button>
                </FormControl>

            </Box>
        </Drawer>
    );
}
