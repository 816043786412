import {convertToDate} from "../../util/DateUtil";
import Client from "../client/Client";
import Subscription from "../subscription/Subscription";

export default class Invoice {
    id: number;
    paymentId: number;
    number: string;
    series: string;
    generated: boolean;
    downloaded: boolean;
    emailSent: boolean;
    createdAt: Date;
    updatedAt: Date;

    constructor(item: any) {
        this.id = item.id;
        this.paymentId = item.paymentId;
        this.number = item.number;
        this.series = item.series;
        this.generated = item.generated;
        this.downloaded = item.downloaded;
        this.emailSent = item.emailSent;

        // @ts-ignore
        this.createdAt = convertToDate(item.createdAt);
        // @ts-ignore
        this.updatedAt = convertToDate(item.updatedAt);
    }
}